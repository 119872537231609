<template>
    <div class="resumeStore">
        <headLine msg='简历库'></headLine>  
        <div class="headTop">
            <span>选择班级：</span>
            <el-cascader
                clearable
                :options="options"
                :props='props'
                v-model="classIdList"
                @change="choseClass"
                placeholder="请选择班级">
            </el-cascader>
            <span class="info">
                该班级总人数：<span class="red">{{(info.sutNum)||0}}</span>，
                总简历数：<span class="red">{{info.resumeNum||0}}</span>，
                完善度70%以上简历数：<span class="red">{{info.usable||0}}</span>
            </span>
            <commonExport :exportUrl='exportUrl'></commonExport>
        </div>
        <el-table
            :data="tableData"
            border
            style="width: 100%">
            <el-table-column
            type="index"
            label="#序号"
            width="60">
            </el-table-column>
           
            <el-table-column
            prop="name"
             :show-overflow-tooltip="true"
            label="姓名">
            </el-table-column>
            <el-table-column
            prop="gender"
             :show-overflow-tooltip="true"
            label="性别">
            </el-table-column>
            <el-table-column
            prop="loginName"
             :show-overflow-tooltip="true"
            label="MOOT账号">
            </el-table-column>   
            <el-table-column
            prop="mobile"
             :show-overflow-tooltip="true"
            label="手机号">
            </el-table-column> 
            <el-table-column
             :show-overflow-tooltip="true"
            label="简历完善度">
                    <el-table-column
                    label="简历1"
                    >
                    <template slot-scope="scope">
                        <span v-if="scope.row.zpResumes" class="red">
                            {{scope.row.zpResumes[0].completePoint+'%'}}
                        </span>
                       <span v-else>暂无简历</span> 
                    </template>
                    </el-table-column>
                    <el-table-column
                    label="简历2"
                    >
                     <template slot-scope="scope">
                        <span v-if="scope.row.zpResumes&&scope.row.zpResumes[1]" class="red">
                            {{scope.row.zpResumes[1].completePoint+'%'}}
                        </span>
                       <span v-else>暂无简历</span> 
                    </template>
                    </el-table-column>
                    <el-table-column
                    label="简历3"
                    >
                     <template slot-scope="scope">
                        <span v-if="scope.row.zpResumes&&scope.row.zpResumes[2]" class="red">
                            {{scope.row.zpResumes[2].completePoint+'%'}}
                        </span>
                       <span v-else>暂无简历</span> 
                    </template>
                    </el-table-column>
            </el-table-column>             
            <el-table-column
            label="查看简历">
                <template slot-scope="scope">
                    <el-button 
                    size="mini"
                    :disabled="!scope.row.zpResumes"
                    type="primary"
                    @click="lookResume(scope.row.zpResumes)"
                    >
                    {{scope.row.zpResumes?'查看简历':'暂无简历'}}
                    </el-button> 
                </template>
            </el-table-column>           
        </el-table>
        
    </div>
</template>
<script>
export default {
    data () {
        return {
            tableData:[],
            options:[],
            info:{},
            classIdList:null,
            props:{
               value:"id",
               label:'name',
               children:'systemOrganizes'
            },
            exportUrl:''
        }
    },
    methods: {
        //查看简历
        lookResume(zpResume){
            sessionStorage.setItem('zpResume',JSON.stringify(zpResume))
             const { href } = this.$router.resolve({
                name: `resumePerson`
            });
            window.open(href, "_blank");
        },
        choseClass(){
            if(this.classIdList.length==3){
                let classId=this.classIdList[2]
                this.getInitList(classId)
            }else{
                this.$message({
                    type:"warning",
                    message:"暂无班级"
                })
            }
            
        },    
        getInitList(classId){
            this.exportUrl=`/tea/class/stus/export?clsId=${classId}`
            let url=`/tea/class/stus?clsId=${classId}`  
            this.$htp.get_(url).then((res)=>{
                let data=res.data
                let {post,unPost,usable,sutNum,resumeNum}=data
                this.tableData=data.details
                this.info={post,unPost,usable,sutNum,resumeNum}
            }) 
        },
        getOrgList(){
            let url=`/tea/org/detail?userId=${sessionStorage.getItem('id')}`  
            this.$htp.get_(url).then((res)=>{
                this.handleOrg(res.data)
            }) 
        },
        handleOrg(data){
            data.forEach(e=>{
                e.systemOrganizes.forEach(v=>{
                    v.systemOrganizes=v.mootClassList
                })
            })
            let classId=data[0].systemOrganizes[0].mootClassList[0].id
            this.classIdList=[data[0].id,data[0].systemOrganizes[0].id,classId]
            this.getInitList(classId)
            this.options=data
            
        }
    },
    mounted(){
        this.getOrgList()
    }
}
</script>
<style lang="scss" scoped>
@import '../../assets/css/common.scss';
</style>
